const PROD = {
  BASE_URL: "https://api.d8adriven.io",
  APP_URL: "https://www.d8adriven.io",
  AMS_URL: "https://www.amazon.com/ap/oa?client_id=amzn1.application-oa2-client.a2ecdc9149254e518c29d5be87143d25&scope=cpc_advertising:campaign_management&response_type=code&redirect_uri=https://www.d8adriven.io/auth/authorization",
  SP_API_URL: "https://mwsdatapull.d8adriven.io",
};

const BETA = {
  BASE_URL: "https://apidev.d8adriven.io",
  APP_URL: "https://dev.d8adriven.io",
  AMS_URL: "https://www.amazon.com/ap/oa?client_id=amzn1.application-oa2-client.a2ecdc9149254e518c29d5be87143d25&scope=cpc_advertising:campaign_management&response_type=code&redirect_uri=https://dev.d8adriven.io/auth/authorization",
  SP_API_URL: "https://apidev.d8adriven.io",
};

const DEV = {
  BASE_URL: "https://apidev.d8adriven.io",
  // BASE_URL: "http://127.0.0.1:8000",
  APP_URL: "http://localhost:3000",
  AMS_URL: "https://www.amazon.com/ap/oa?client_id=amzn1.application-oa2-client.a2ecdc9149254e518c29d5be87143d25&scope=cpc_advertising:campaign_management&response_type=code&redirect_uri=https://dev.d8adriven.io/auth/authorization",
  SP_API_URL: "https://apidev.d8adriven.io",
};

const MAP = {
  production: PROD,
  beta: BETA,
  development: DEV,
};
const Config = MAP[process.env.REACT_APP_BUILD];

export default Config;
